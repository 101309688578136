import React, { memo } from 'react';

import {
  Item,
  ItemContainer,
  ItemDescricao,
  ItemInfo,
  ItemNome,
  ItemRadioButton,
  Secao,
  SecaoAviso,
  SecaoConteudo,
  SecaoDescricao,
  SecaoTitulo,
  SecaoTituloContainer,
} from '../globalStyles';

const Tamanhos = ({ tamanhos, tamanhoSelecionado, selecionarTamanho }) => {
  return (
    <Secao>
      <SecaoTituloContainer>
        <SecaoTitulo>Tamanhos</SecaoTitulo>
        <SecaoDescricao>Selecione o tamanho</SecaoDescricao>
        <SecaoAviso>Obrigatório</SecaoAviso>
      </SecaoTituloContainer>
      <SecaoConteudo>
        {tamanhos.map((tamanho, index) => (
          <ItemContainer
            key={`${tamanho.descricao}-Tamanho-Produto`}
            onPress={() => selecionarTamanho(index)}
          >
            <Item divisor={!!index}>
              <ItemInfo>
                <ItemNome>{tamanho.descricao}</ItemNome>
                <ItemDescricao>{tamanho.precoVendaFormatado}</ItemDescricao>
              </ItemInfo>
              <ItemRadioButton
                onPress={() => selecionarTamanho(index)}
                value={tamanho.descricao}
                status={
                  tamanhoSelecionado.descricao === tamanho.descricao
                    ? 'checked'
                    : 'unchecked'
                }
              />
            </Item>
          </ItemContainer>
        ))}
      </SecaoConteudo>
    </Secao>
  );
};

export default memo(Tamanhos);
