import { MaterialIcons } from '@expo/vector-icons';
import styled from 'styled-components/native';

import theme from '../../theme/index';

export const Container = styled.View`
  background-color: ${theme.cores.superficie};
  flex-direction: row;
  align-items: center;
  padding: 8px 10px;
  border-radius: 4px;
  height: 50px;
`;

export const Icone = styled(MaterialIcons).attrs(() => ({
  color: theme.cores.cinzas.c2,
  size: 20,
}))`
  margin-right: 8px;
`;

export const TextInput = styled.TextInput.attrs(() => ({
  placeholderTextColor: theme.cores.cinzas.c2,
}))`
  font-size: ${theme.fontes.normal};
  background-color: transparent;
  color: ${theme.cores.texto.sobreSuperficie};
  flex: 1;
  height: 100%;
`;
