import api from './api';

export async function listarGrupos({ listarProdutos = false }) {
  const res = await api.get(`/grupos`, {
    params: {
      listarProdutos,
    },
  });
  return res.data;
}
