import React from 'react';
import { Platform } from 'react-native';
import { enableScreens } from 'react-native-screens';

import { createStackNavigator } from '@react-navigation/stack';

// import { createNativeStackNavigator as createStackNavigator } from 'react-native-screens/native-stack';

import NotFound from '../screens/NotFound';
import SessaoCodigo from '../screens/SessaoCodigo';
import SessaoNomeCliente from '../screens/SessaoNomeCliente';
import SessaoPorQrCode from '../screens/SessaoPorQrCode';
import TelaInicial from '../screens/TelaInicial';
import TelaInicialPwa from '../screens/TelaInicialPwa';

enableScreens();
const Stack = createStackNavigator();

const AutenticacaoRoutes = () => {
  return (
    <>
      <Stack.Navigator
        initialRouteName={
          Platform.OS === 'web' ? 'TelaInicialPwa' : 'TelaInicial'
        }
        screenOptions={{
          headerShown: false,
        }}
      >
        <Stack.Screen name="NotFound" component={NotFound} />

        <Stack.Screen name="TelaInicialPwa" component={TelaInicialPwa} />

        <Stack.Screen name="TelaInicial" component={TelaInicial} />

        <Stack.Screen name="SessaoCodigo" component={SessaoCodigo} />

        <Stack.Screen name="SessaoPorQrCode" component={SessaoPorQrCode} />

        <Stack.Screen name="SessaoNomeCliente" component={SessaoNomeCliente} />
      </Stack.Navigator>
    </>
  );
};

export default AutenticacaoRoutes;
