import { MaterialIcons } from '@expo/vector-icons';
import styled from 'styled-components/native';

import BotaoBase from '../../../components/BotaoBase';
import theme from '../../../theme/index';

export const Quantidade = styled.View`
  border: ${({ semBorda = false }) =>
    semBorda ? 'none' : `2px solid ${theme.cores.cinzas.c1}`};

  border-radius: 4px;

  flex-direction: row;
  align-items: center;
`;

export const QuantidadeBotao = styled(BotaoBase)`
  padding: 12px 8px;
`;

export const QuantidadeBotaoIconeAdd = styled(MaterialIcons).attrs(
  ({ desabilitado }) => ({
    size: 25,
    color: desabilitado ? theme.cores.cinzas.c2 : '#bcbcbc',
    name: 'add',
  }),
)``;

export const QuantidadeBotaoIconeRemover = styled(MaterialIcons).attrs(
  ({ desabilitado }) => ({
    size: 25,
    color: desabilitado ? theme.cores.cinzas.c2 : '#bcbcbc',
    name: 'remove',
  }),
)``;

export const QuantidadeValor = styled.Text`
  font-size: ${theme.fontes.normal};
  color: ${theme.cores.texto.sobreSuperficie};
  min-width: 40px;
  text-align: center;
`;
