import { Platform } from 'react-native';

import styled, { css } from 'styled-components/native';

import theme from '../../theme/index';

export const ProdutosLista = styled.FlatList.attrs(() => ({
  showsVerticalScrollIndicator: false,
}))`
  ${Platform.select({
    web: css``,
    android: css`
      flex: 1;
      margin-bottom: 8px;
    `,
    ios: css`
      flex: 1;
      margin-bottom: 8px;
    `,
  })}
`;

export const Carregando = styled.View`
  ${Platform.select({
    web: css`
      align-items: center;
      justify-content: center;
      color: ${theme.cores.fundo};
      margin-top: 16px;
    `,
    android: css`
      flex: 1;
      align-items: center;
      justify-content: center;
      color: ${theme.cores.fundo};
      margin-top: 16px;
    `,
    ios: css`
      flex: 1;
      align-items: center;
      justify-content: center;
      color: ${theme.cores.fundo};
      margin-top: 16px;
    `,
  })}
`;
