import styled from 'styled-components/native';

import theme from '../../theme/index';
import BotaoBase from '../BotaoBase';

export const Container = styled(BotaoBase)`
  background-color: ${theme.cores.primaria};
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  height: 50px;
  padding: 8px 12px;
`;

export const Texto = styled.Text`
  font-size: ${theme.fontes.normal};
  font-weight: bold;
  color: ${theme.cores.texto.sobrePrimaria};
  text-align: center;
`;
