import styled from 'styled-components/native';

import BotaoBase from '../../../components/BotaoBase';
import theme from '../../../theme/index';

export const Container = styled.View`
  bottom: 30px;
  background-color: ${theme.cores.superficie};
  flex-direction: row;
  border-top-width: 1px;
  border-top-color: ${theme.cores.cinzas.c1};
  padding: 16px 8px;
`;

export const BotaoAdicionar = styled(BotaoBase).attrs(() => ({
  containerStyle: {
    flex: 1,
  },
}))`
  border-radius: 4px;
  padding: 12px 16px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ desabilitado }) =>
    desabilitado ? theme.cores.cinzas.c2 : theme.cores.unitech};
  margin-left: 12px;
  flex: 1;
`;

export const BotaoAdicionarTexto = styled.Text`
  margin-right: 6px;
  font-size: ${theme.fontes.normal};
  color: ${theme.cores.texto.sobrePrimaria};
  font-weight: bold;
`;

export const BotaoAdicionarValor = styled(BotaoAdicionarTexto)`
  margin-right: 0px;
`;
