import { Platform } from 'react-native';

import styled, { css } from 'styled-components/native';

import theme from '../../../theme/index';

export const ProdutoImagem = styled.ImageBackground`
  height: ${props => props.altura}px;
  width: 100%;
`;

export const CardapioVisualizacao = styled.Text`
  font-size: ${theme.fontes.normal};
  font-weight: bold;
  color: #fff;
  text-align: center;
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  margin-top: auto;
`;

export const ProdutoContainer = styled.View`
  ${Platform.select({
    web: css`
      background-color: ${theme.cores.superficie};
      padding: 0 16px;
      bottom: 30px;
      margin: 20px 0;
    `,
    android: css`
      background-color: ${theme.cores.superficie};
      padding: 0 16px;
      margin: 20px 0;
    `,
    ios: css`
      background-color: ${theme.cores.superficie};
      padding: 0 16px;
      margin: 20px 0;
    `,
  })}
`;

export const ProdutoDescricao = styled.Text`
  font-size: ${theme.fontes.grande};
  font-weight: bold;
  color: ${theme.cores.texto.sobreSuperficie};
`;

export const ProdutoComposicao = styled.Text`
  font-size: ${theme.fontes.normal};
  color: ${theme.cores.cinzas.c3};
  margin-top: 4px;
`;

export const PrecoAPatirContainer = styled.View`
  flex-direction: row;
  align-items: baseline;
`;

export const PrecoAPatir = styled.Text`
  font-size: ${theme.fontes.media};
  line-height: ${theme.fontes.media};
  color: ${theme.cores.cinzas.c2};
  margin-right: 4px;
`;

export const ProdutoPreco = styled.Text`
  font-size: ${theme.fontes.grande};
  line-height: ${theme.fontes.grande};
  color: ${theme.cores.sucesso};

  margin-top: 21px;
`;
