import React from 'react';

import { Container, CarregandoIcone } from './styles';

const Carregando = () => {
  return (
    <Container>
      <CarregandoIcone />
    </Container>
  );
};

export default Carregando;
