import { Platform } from 'react-native';

import { MaterialIcons } from '@expo/vector-icons';
import styled, { css } from 'styled-components/native';

import Botao from '../../components/Botao';
import theme from '../../theme/index';

export const ContainerInterno = styled.View`
  ${Platform.select({
    web: css`
      height: 100vh;
      padding: 16px;
      background-color: ${theme.cores.superficie};
    `,
    android: css`
      padding: 16px;
      background-color: ${theme.cores.superficie};
      flex: 1;
    `,
    ios: css`
      padding: 16px;
      background-color: ${theme.cores.superficie};
      flex: 1;
    `,
  })}
`;

export const Cabecalho = styled.View`
  position: relative;
  padding: 16px;
  border-bottom-width: 1px;
  border-bottom-color: ${theme.cores.cinzas.c1};
  background-color: ${theme.cores.superficie};

  justify-content: center;
`;

export const BotaoVoltar = styled.TouchableOpacity`
  position: absolute;
  left: 0;
  padding: 16px;
  align-items: center;
  justify-content: center;
`;

export const IconeVoltar = styled(MaterialIcons).attrs(() => ({
  name: 'arrow-back',
  size: 25,
}))`
  color: ${theme.cores.texto.sobreSuperficie};
`;

export const Texto = styled.Text`
  font-size: ${theme.fontes.grande};
  line-height: ${theme.fontes.grande};
  color: ${theme.cores.texto.sobreSuperficie};
  font-weight: bold;
  margin: 0 50px;
  text-align: center;
`;

export const Input = styled.TextInput`
  font-size: ${theme.fontes.grande};
  color: ${theme.cores.texto.sobreSuperficie};
  background-color: ${theme.cores.superficie};
  padding: 8px 12px;
  text-align: center;
  flex: 1;
`;

export const BotaoNovaSessao = styled(Botao).attrs(() => ({
  textoStyle: {
    color: '#fff',
  },
}))`
  ${Platform.select({
    web: css`
      background-color: ${props =>
        props.desabilitado ? theme.cores.cinzas.c3 : '#003366'};
      margin-top: auto;
      margin-bottom: 100px;
    `,
    android: css`
      background-color: ${props =>
        props.desabilitado ? theme.cores.cinzas.c3 : '#003366'};
      margin-top: auto;
    `,
    ios: css`
      background-color: ${props =>
        props.desabilitado ? theme.cores.cinzas.c3 : '#003366'};
      margin-top: auto;
    `,
  })}
`;
