import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { Alert, Platform } from 'react-native';

import AsyncStorage from '@react-native-async-storage/async-storage';

import ModalPwa from '../components/ModalPwa';
import api from '../services/api';
import { criarSessao, mostarSessao } from '../services/sessoes';
import { criarGarcom } from '../services/garcons';
import { useCarrinho } from './carrinho';
import { useTema } from './tema';

const SessaoContext = createContext({});

export const SessaoProvider = ({ children }) => {
  const [sessao, setSessao] = useState(null);
  // const [nrContrato, setNrContrato] = useState('');
  const [carregando, setCarregando] = useState(true);
  const { tema, gerarTema } = useTema();
  const { limpar: limparCarrinho } = useCarrinho();
  const [isVisible, setIsVisible] = React.useState(false);

  const iniciarSessao = useCallback(
    async ({
      idModulo,
      nomeCliente,
      tipoModulo,
      contrato,
      vendaSemAutenticacao,
      verCategorias,
      verChamaGarcom,
      orderDescricao,
      utilizaCouvert,
      precoCouvert,
      moduloMesaCobrarCouvertNaAbertura,
      moduloMesaQtdeCouvertNaAbertura,
      moduloCartaoCobrarCouvertNaAbertura,
      moduloCartaoQtdeCouvertNaAbertura,
      vendaBloqueadaCartaoLivre,
      empresa,
    }) => {
      api.defaults.headers.contrato = contrato;

      const novaSessao = await criarSessao({
        idModulo,
        nomeCliente,
        tipoModulo: tipoModulo === 'MESA' ? 'MESA' : 'CARTAO',
        vendaSemAutenticacao,
        verCategorias,
        verChamaGarcom,
        orderDescricao,
        utilizaCouvert,
        precoCouvert,
        moduloMesaCobrarCouvertNaAbertura,
        moduloMesaQtdeCouvertNaAbertura,
        moduloCartaoCobrarCouvertNaAbertura,
        moduloCartaoQtdeCouvertNaAbertura,
        vendaBloqueadaCartaoLivre,
        empresa,
      });

      if (idModulo === '0') {
        novaSessao.empresa.configuracoes.cardapioVisualizacao = true;
        novaSessao.vendaBloqueadaCartaoLivre = false;
      }

      await AsyncStorage.setItem(
        '@menucheff-cardapio:sessao',
        JSON.stringify({
          id: novaSessao.id,
          contrato,
        }),
      );

      gerarTema(novaSessao.empresa.configuracoes.tema);

      setSessao(novaSessao);
    },
    [gerarTema],
  );

  const chamaGarcom = useCallback(
    async ({
      idModulo,
      contrato,
    }) => {
      api.defaults.headers.contrato = contrato;

      try {
        await criarGarcom({
          idModulo,
          nrContrato: contrato,
        });
      } catch (error) {
        console.log(error.message)
      }

      if (idModulo === '0') {
        novaSessao.empresa.configuracoes.cardapioVisualizacao = true;
        novaSessao.vendaBloqueadaCartaoLivre = false;
      }
    },
    [],
  );

  const encerrarSessao = useCallback(async () => {
    limparCarrinho();
    setSessao(null);
    await AsyncStorage.clear();
  }, [limparCarrinho]);

  const verificarSessao = useCallback(() => {
    return new Promise(resolve => {
      mostarSessao(sessao.id)
        .then(() => resolve(true))
        .catch(() => {
          if (Platform.OS === 'web') {
            setIsVisible(true);
          }
          Alert.alert('MenuCheff', 'Sua sessão foi encerrada', [
            {
              text: 'Sair',
              onPress: () => {
                encerrarSessao().then(() => {
                  resolve(false);
                });
              },
            },
          ]);
        });
    });
  }, [encerrarSessao, sessao]);

  useEffect(() => {
    (async () => {
      const sessaoArmazenada = JSON.parse(
        await AsyncStorage.getItem('@menucheff-cardapio:sessao'),
      );
      if (sessaoArmazenada) {
        api.defaults.headers.contrato = sessaoArmazenada.contrato;
        try {
          const sessaoNova = await mostarSessao(sessaoArmazenada.id);
          setSessao(sessaoNova);
          gerarTema(sessaoNova.empresa.configuracoes.tema);
        } catch (error) {
          await encerrarSessao();
        } finally {
          setCarregando(false);
        }

        return;
      }

      setCarregando(false);
    })();
  }, [encerrarSessao, gerarTema]);

  return (
    <SessaoContext.Provider
      value={{
        sessao,
        carregando,
        iniciarSessao,
        chamaGarcom,
        encerrarSessao,
        verificarSessao,
        corPadrao: sessao ? sessao.empresa.configuracoes.tema : '',
        empresa: sessao ? sessao.empresa : {},
        tema,
      }}
    >
      {Platform.OS === 'web' && (
        <ModalPwa
          visivel={isVisible}
          titulo="Sua sessão foi encerrada"
          onClose={() => setIsVisible(false)}
          swipeDirection={null}
        />
      )}
      {children}
    </SessaoContext.Provider>
  );
};

export function useSessao() {
  const context = useContext(SessaoContext);

  if (!context) {
    throw new Error('AuthContext must be used within an AuthProvider');
  }

  return context;
}
