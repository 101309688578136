import React, { useState } from 'react';
import { Feather } from '@expo/vector-icons';

import { Container, InputText, IconContainer } from './styles';
import { COLORS } from '../../constants';
import { TouchableOpacity } from 'react-native';

export function Input({ iconName, value, onPress, ...rest }) {
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  function handleInputFocus() {
    setIsFocused(true);
  }

  function handleInputBlur() {
    setIsFocused(false);
    setIsFilled(!!value);
  }

  return (
    <Container>
      <InputText
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        isFocused={isFocused}
        color={isFocused || isFilled ? COLORS.black : COLORS.black}
        {...rest}
      />
      <IconContainer isFocused={isFocused}>
        <TouchableOpacity onPress={onPress}>
        <Feather
          name={iconName}
          size={24}
          color={isFocused || isFilled ? COLORS.black : COLORS.black}
        />
        </TouchableOpacity>
      </IconContainer>
    </Container>
  );
}
