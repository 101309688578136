import React from 'react';

import { Container, Icone, TextInput } from './styles';

const Input = ({ icone, style, ...rest }) => {
  return (
    <Container style={style}>
      {icone && <Icone name={icone} />}
      <TextInput {...rest} />
    </Container>
  );
};

export default Input;
