import { Platform } from 'react-native';

import styled, { css } from 'styled-components/native';

import theme from '../../theme/index';

export const Container = styled.View`
  ${Platform.select({
    web: css`
      background-color: ${theme.cores.fundo};
      align-items: center;
      justify-content: center;
      padding: 16px;
    `,
    android: css`
      flex: 1;
      background-color: ${theme.cores.fundo};
      align-items: center;
      justify-content: center;
      padding: 16px;
    `,
    ios: css`
      flex: 1;
      background-color: ${theme.cores.fundo};
      align-items: center;
      justify-content: center;
      padding: 16px;
    `,
  })}
`;

export const ErroImagem = styled.Image`
  height: 150px;
  width: 150px;
`;

export const ErroTexto = styled.Text`
  font-size: ${theme.fontes.grande};
  color: ${theme.cores.texto.sobreFundo};
  font-weight: bold;
  margin: 24px 16px;
  text-align: center;
`;

export const RecarregarBotao = styled.TouchableOpacity`
  padding: 8px;
  margin-top: 32px;
`;

export const Recarregar = styled.Text`
  font-size: ${theme.fontes.normal};
  color: ${theme.cores.primaria};
  text-align: center;
`;
