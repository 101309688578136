import React from 'react';

import { ContainerNormal, ContainerRolavel, StatusBarFundo } from './styles';

const Container = ({
  children,
  rolavel,
  corStatusBar,
  temStatusBar = true,
  style,
  ...rest
}) => {
  return rolavel ? (
    <>
      {temStatusBar && <StatusBarFundo corStatusBar={corStatusBar} />}
      <ContainerRolavel style={style} {...rest}>
        {children}
      </ContainerRolavel>
    </>
  ) : (
    <>
      {temStatusBar && <StatusBarFundo corStatusBar={corStatusBar} />}
      <ContainerNormal style={style} {...rest}>
        {children}
      </ContainerNormal>
    </>
  );
};

export default Container;
