import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';

import Botao from '../../../components/Botao';
import theme from '../../../theme/index';

export const Container = styled.View`
  ${Platform.select({
    web: css`
      padding: 16px;
      /* background-color: ${theme.cores.primaria}; */
      flex-direction: row;
    `,
    android: css`
      padding: 16px;
      background-color: ${({ theme }) => theme.cores.primaria};
      flex-direction: row;
    `,
    ios: css`
      padding: 16px;
      background-color: ${({ theme }) => theme.cores.primaria};
      flex-direction: row;
    `,
  })}
`;

export const EmpresaLogo = styled.Image`
  border-radius: 8px;
  height: 75px;
  width: 75px;
`;

export const EmpresaInfo = styled.View`
  flex: 1;
  margin-left: 12px;
`;

export const EmpresaNome = styled.Text`
  font-size: ${theme.fontes.normal};
  color: ${theme.cores.texto.sobrePrimaria};
  font-weight: bold;
`;

export const EmpresaEndereco = styled.Text`
  font-size: ${theme.fontes.pequena};
  color: ${theme.cores.texto.sobrePrimaria};
  margin-top: 4px;
`;

export const ModuloContainer = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
`;

export const Modulo = styled.Text`
  font-size: ${theme.fontes.normal};
  color: ${theme.cores.texto.sobrePrimaria};
  font-weight: bold;
`;

export const ModuloBotaoSair = styled(Botao)`
  background: ${theme.cores.cinzas.c2};
  width: 100px;
  height: 40px;
`;

export const ModuloBotaoGarcom = styled(Botao)`
  background: ${theme.cores.atencao};
  width: 100px;
  height: 40px;
`;
