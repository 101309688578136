import React from 'react';

import {
  Container,
  ErroImagem,
  ErroTexto,
  RecarregarBotao,
  Recarregar,
} from './styles';

const Erro = ({ imagem, mensagem, recarregar }) => {
  return (
    <Container>
      <ErroImagem source={imagem} />
      <ErroTexto>{mensagem}</ErroTexto>

      {!!recarregar && (
        <RecarregarBotao onPress={recarregar}>
          <Recarregar>Recarregar</Recarregar>
        </RecarregarBotao>
      )}
    </Container>
  );
};

export default Erro;
