import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, StatusBar, Alert, Platform, View } from 'react-native';

import { useNavigation, useIsFocused } from '@react-navigation/native';
import { BarCodeScanner } from 'expo-barcode-scanner';
import { Camera } from 'expo-camera';
import * as Permissions from 'expo-permissions';
import { debounce } from 'lodash';

import {
  BotaoCod,
  Container,
  InfoContainer,
  TextoInfo,
  TextoInfoContainer,
} from './styles';

const SessaoPorQrCode = () => {
  const [temPermissao, setTemPermissao] = useState(null);
  const [exibirCamera, setExibirCamera] = useState(false);

  const navigation = useNavigation();
  const focado = useIsFocused();

  const escanearQrCode = useRef(
    debounce(
      qr => {
        try {
          if(String(qr.data).indexOf("https://www.cardapiodigital.menucheff.com/") !== -1) {
            const codigos = qr.data.split('/')[3].split("M");
            const codigosCartao = qr.data.split('/')[3].split("C");
            let dadosMesaCartao = null;

            if(codigos !== undefined){
              dadosMesaCartao = {
                "contrato": Number(codigos[1]),
                "idModulo": 1,
                "tipoModulo": "MESA",
                "url": qr.data,
              }
            } else {
              dadosMesaCartao = {
                "contrato": Number(codigosCartao[1]),
                "idModulo": 1,
                "tipoModulo": "MESA",
                "url": qr.data,
              }
            }
            navigation.navigate('SessaoNomeCliente', dadosMesaCartao);
          } else {
              const dados = JSON.parse(qr.data);
              navigation.navigate('SessaoNomeCliente', dados);
          }
        } catch (error) {
          Alert.alert('MenuCheff', 'QR Code Inválido');
        }
      },
      500,
      { leading: true, trailing: false },
    ),
  );

  function sessaoCod() {
    navigation.navigate('SessaoCodigo');
  }

  const isFirefox = typeof InstallTrigger !== 'undefined';

  useEffect(() => {
    (async () => {
      if (Platform.OS === 'web') {
        if (isFirefox) {
          setTemPermissao(true);
        } else {
          // const { status } = await Permissions.askAsync(Permissions.CAMERA);
          // setTemPermissao(status === 'granted');
          setTemPermissao(true);
          setExibirCamera(true);
        }
      } else {
        const { status } = await Permissions.askAsync(Permissions.CAMERA);
        setTemPermissao(status === 'granted');
      }
    })();
  }, [isFirefox]);

  useEffect(() => {
    setTimeout(() => setExibirCamera(focado), 650);
  }, [focado]);

  return (
    <Container>
      <StatusBar barStyle="light-content" translucent />

      {temPermissao === null && (
        <TextoInfo>Solicitanto permissão da câmera</TextoInfo>
      )}

      {temPermissao === false && (
        <View>
          <Camera
            ratio="16:9"
            style={StyleSheet.absoluteFillObject}
            onBarCodeScanned={escanearQrCode.current}
            barCodeScannerSettings={{
              barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr],
            }}
          />
          <TextoInfo>Sem permissão para a câmera</TextoInfo>
        </View>
      )}

      {temPermissao && (
        <>
          <InfoContainer>
            <TextoInfoContainer>Escaneie o QR Code</TextoInfoContainer>
          </InfoContainer>

          {exibirCamera && (
            <Camera
              ratio="16:9"
              style={StyleSheet.absoluteFillObject}
              onBarCodeScanned={escanearQrCode.current}
              barCodeScannerSettings={{
                barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr],
              }}
            />
          )}
        </>
      )}

      <BotaoCod onPress={sessaoCod}>
        <TextoInfoContainer>Digite o código</TextoInfoContainer>
      </BotaoCod>
    </Container>
  );
};

export default SessaoPorQrCode;
