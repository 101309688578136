import React, { useCallback, useState } from 'react';
import { Image } from 'react-native';

const ImagemFallback = ({ style, resizeMode = 'cover', src, fallbackImg }) => {
  const [source, setSource] = useState(() => ({
    uri: src,
  }));

  const carregarFallback = useCallback(() => {
    setSource(fallbackImg);
  }, [fallbackImg]);

  return src ? (
    <Image
      style={style}
      source={source}
      onError={carregarFallback}
      resizeMode={resizeMode}
    />
  ) : (
    <Image style={style} source={fallbackImg} resizeMode={resizeMode} />
  );
};

export default ImagemFallback;
