import { Platform } from 'react-native';

import styled, { css } from 'styled-components/native';

import Botao from '../../components/Botao';
import theme from '../../theme/index';

export const Carregando = styled.View`
  ${Platform.select({
    web: css`
      align-items: center;
      justify-content: center;
      color: ${theme.cores.fundo};
      margin-top: 24px;
    `,
    android: css`
      flex: 1;
      align-items: center;
      justify-content: center;
      color: ${theme.cores.fundo};
      margin-top: 24px;
    `,
    ios: css`
      flex: 1;
      align-items: center;
      justify-content: center;
      color: ${theme.cores.fundo};
      margin-top: 24px;
    `,
  })}
`;

export const CarregandoIcone = styled.ActivityIndicator.attrs(() => ({
  size: 80,
  color: theme.cores.primaria,
}))``;

export const Produtos = styled.FlatList.attrs(() => ({
  showsVerticalScrollIndicator: false,
}))`
  ${Platform.select({
    web: css`
      height: 100vh;
      margin-top: 16px;
    `,
    android: css`
      flex: 50;
      margin-top: 16px;
    `,
    ios: css`
      flex: 50;
      margin-top: 16px;
    `,
  })}
`;

export const BotaoEncerrarContaier = styled.View`
  ${Platform.select({
    web: css`
      padding: 16px;
      margin-bottom: 30%;
    `,
    android: css`
      margin-top: auto;
      padding: 16px;
    `,
    ios: css`
      margin-top: auto;
      padding: 16px;
    `,
  })}
`;

export const TotalTexto = styled.Text`
  color: ${theme.cores.texto.sobreSuperficie};
  font-size: ${theme.fontes.normal};
  font-weight: bold;
  margin-bottom: 4px;
  align-self: flex-end;
`;

export const TotalValor = styled(TotalTexto)`
  color: ${theme.cores.sucesso};
`;

export const BotaoEncerrar = styled(Botao)`
  ${Platform.select({
    web: css`
      background-color: ${theme.cores.unitech};
      margin-bottom: 40px;
    `,
    android: css`
      background-color: ${theme.cores.unitech};
    `,
    ios: css`
      background-color: ${theme.cores.unitech};
    `,
  })}
`;

export const PedidosVazioContainer = styled.View`
  ${Platform.select({
    web: css`
      align-items: center;
      justify-content: center;
      padding: 16px;
      margin-top: 50%;
    `,
    android: css`
      background-color: transparent;
      align-items: center;
      justify-content: center;
      flex: 1;
      padding: 16px;
    `,
    ios: css`
      background-color: transparent;
      align-items: center;
      justify-content: center;
      flex: 1;
      padding: 16px;
    `,
  })}
`;

export const PedidosVazioImagem = styled.Image`
  height: 200px;
  width: 200px;
`;

export const PedidosVazioTitulo = styled.Text`
  color: ${theme.cores.texto.sobreSuperficie};
  font-size: ${theme.fontes.grande};
  font-weight: bold;

  margin: 16px 16px 0 16px;
  font-size: ${theme.fontes.extraGrande};
`;

export const PedidosVazioMensagem = styled.Text`
  color: ${theme.cores.cinzas.c2};
  font-size: ${theme.fontes.media};
  text-align: center;
  font-weight: bold;
  margin-top: 4px;
`;

export const Produto = styled.View`
  background-color: ${theme.cores.superficie};
  padding: 12px 8px;
  flex-direction: row;
  margin: 0 16px;
  align-items: center;
`;

export const ProdutoDescricao = styled.Text.attrs(() => ({
  ellipsizeMode: 'tail',
  numberOfLines: 1,
}))`
  font-size: ${theme.fontes.normal};
  color: ${theme.cores.texto.sobreSuperficie};
  font-weight: bold;
  margin: 0 10px;
  flex: 1;
`;

export const ProdutoQuantidade = styled.Text`
  font-size: ${theme.fontes.normal};
  color: ${theme.cores.cinzas.c4};
`;

export const ProdutoPreco = styled.Text`
  font-size: ${theme.fontes.normal};
  color: ${theme.cores.sucesso};
  margin-left: auto;
`;
