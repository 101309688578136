import React, { useCallback, useState } from 'react';

import {
  Quantidade,
  QuantidadeBotao,
  QuantidadeBotaoIconeRemover,
  QuantidadeBotaoIconeAdd,
  QuantidadeValor,
} from './styles';

const BotaoQuantidade = ({
  desabilitado = false,
  semBorda = false,
  diminuir,
  aumentar,
  qtdInicial = 0,
  qtdMaxima,
}) => {
  const [quantidade, setQuantidade] = useState(qtdInicial);

  const aumentarQuantidade = useCallback(() => {
    if (!desabilitado) {
      aumentar();
      setQuantidade(qtd => qtd + 1);
    }
  }, [aumentar, desabilitado]);

  const diminuirQuantidade = useCallback(() => {
    diminuir();
    setTimeout(() => setQuantidade(qtd => Math.max(qtdInicial, qtd - 1)), 150);
  }, [diminuir, qtdInicial]);

  return (
    <Quantidade semBorda={semBorda}>
      {quantidade > 0 && (
        <>
          <QuantidadeBotao
            onPress={diminuirQuantidade}
            desabilitado={quantidade === qtdInicial}
          >
            <QuantidadeBotaoIconeRemover
              desabilitado={quantidade === qtdInicial}
            />
          </QuantidadeBotao>
          <QuantidadeValor>{quantidade}</QuantidadeValor>
        </>
      )}
      <QuantidadeBotao
        desabilitado={desabilitado || qtdMaxima === quantidade}
        onPress={aumentarQuantidade}
      >
        <QuantidadeBotaoIconeAdd
          desabilitado={desabilitado || qtdMaxima === quantidade}
        />
      </QuantidadeBotao>
    </Quantidade>
  );
};

export default BotaoQuantidade;
