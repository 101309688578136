import { Dimensions, Platform } from 'react-native';

import { MaterialIcons } from '@expo/vector-icons';
import styled, { css } from 'styled-components/native';

import ContainerComponente from '../../components/Container';
import ImagemFallback from '../../components/ImagemFallback';
import theme from '../../theme/index';

const larguraItem = Dimensions.get('screen').width * 0.30 - 16;
const alturaItemImagem = larguraItem * 0.7;

export const Container = styled(ContainerComponente).attrs(({ rolavel }) => ({
  corStatusBar: theme.cores.primaria,
  rolavel,
}))`
  background-color: ${theme.cores.fundo};
`;

export const ContainerInterno = styled.FlatList.attrs(() => ({
  showsVerticalScrollIndicator: false,
}))`
  ${Platform.select({
    web: css`
      height: 100vh;
      background-color: ${theme.cores.fundo};
    `,
    android: css`
      flex: 1;
      background-color: ${theme.cores.fundo};
    `,
    ios: css`
      flex: 1;
      background-color: ${theme.cores.fundo};
    `,
  })}
`;

export const Secao = styled.View`
  padding: 20px 16px;
  background-color: ${theme.cores.fundo};
`;

export const SecaoTituloContainer = styled.View`
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const SecaoTitulo = styled.Text.attrs(() => ({
  ellipsizeMode: 'tail',
  numberOfLines: 1,
}))`
  color: ${theme.cores.texto.sobreSuperficie};
  font-size: ${theme.fontes.grande};
  font-weight: bold;
  flex: 1;
  margin-right: 30px;
  text-transform: capitalize;
`;

export const Itens = styled.FlatList.attrs(() => ({
  showsHorizontalScrollIndicator: false,
  horizontal: true,
  persistentScrollbar: true,
}))``;

export const ItensBuscar = styled.FlatList.attrs(() => ({
  showsVerticalScrollIndicator: true,
  vertical: true,
  persistentScrollbar: true,
}))``;

export const Item = styled.TouchableOpacity`
  margin: 0 8px;
  width: ${larguraItem}px;
`;

export const ItemImagem = styled(ImagemFallback).attrs(({ src }) => ({
  resizeMode: src ? 'contain' : 'contain',
}))`
  height: ${alturaItemImagem}px;
  width: 100%;
  border-radius: 4px;
  background-color: #fff;
`;

export const ItemNome = styled.Text.attrs(() => ({
  ellipsizeMode: 'tail',
  numberOfLines: 2,
}))`
  color: ${theme.cores.texto.sobreSuperficie};
  font-size: ${theme.fontes.media};
  font-weight: bold;
  margin-top: 8px;
  text-transform: capitalize;
  width: 95%;
`;

export const ItemPrecoContainer = styled.View`
  flex-direction: row;
  align-items: baseline;
  flex-wrap: wrap;
`;

export const ItemPreco = styled.Text`
  color: ${theme.cores.sucesso};
  font-size: ${theme.fontes.media};
  margin-top: 2px;
`;

export const ItemPrecoAPartir = styled.Text`
  color: ${theme.cores.cinzas.c2};
  font-size: ${theme.fontes.pequena};
  margin-top: 2px;
`;

export const VerTodosContainer = styled.View`
  margin-left: 16px;
  align-items: center;
  margin-top: ${alturaItemImagem / 2 - 34}px;
`;

export const VerTodos = styled.TouchableOpacity`
  ${Platform.select({
    web: css`
      height: 60px;
      width: 60px;
      border-radius: 30px;
      align-items: center;
      justify-content: center;
      background-color: ${theme.cores.cinzas.c4};
    `,
    android: css`
      height: 60px;
      width: 60px;
      border-radius: 30px;
      align-items: center;
      justify-content: center;
      background-color: ${theme.cores.cinzas.c4};
    `,
    ios: css`
      height: 60px;
      width: 60px;
      border-radius: 30px;
      align-items: center;
      justify-content: center;
      background-color: ${theme.cores.cinzas.c4};
    `,
  })}
`;

export const VerTodosTexto = styled.Text`
  ${Platform.select({
    web: css`
      font-size: ${theme.fontes.media};
      color: ${theme.cores.cinzas.c4};
      margin-top: 8px;
    `,
    android: css`
      font-size: ${theme.fontes.media};
      color: ${theme.cores.cinzas.c4};
      margin-top: 8px;
    `,
    ios: css`
      font-size: ${theme.fontes.media};
      color: ${theme.cores.cinzas.c4};
      margin-top: 8px;
    `,
  })}
`;

export const SetaIcone = styled(MaterialIcons).attrs(() => ({
  color: theme.cores.texto.sobrePrimaria,
  size: 40,
  name: 'arrow-forward',
}))``;
