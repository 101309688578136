import { Platform } from 'react-native';

import styled, { css } from 'styled-components/native';

import theme from '../../theme/index';

export const Container = styled.View`
  ${Platform.select({
    web: css`
      align-items: center;
      justify-content: center;
      color: ${theme.cores.fundo};
    `,
    android: css`
      align-items: center;
      justify-content: center;
      color: ${theme.cores.fundo};
      flex: 1;
    `,
    ios: css`
      align-items: center;
      justify-content: center;
      color: ${theme.cores.fundo};
      flex: 1;
    `,
  })}
`;

export const CarregandoIcone = styled.ActivityIndicator.attrs(() => ({
  size: 80,
  color: theme.cores.primaria,
}))``;
