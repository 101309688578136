import styled from 'styled-components/native';

import theme from '../../theme/index';
import ImagemFallback from '../ImagemFallback';

export const Produto = styled.TouchableOpacity`
  background-color: ${theme.cores.superficie};
  margin: 0 16px 16px;
  padding: 8px;
  border-radius: 4px;
  flex-direction: row;

  display: ${({ visivel }) => (visivel ? 'flex' : 'none')};
`;

export const ProdutoImagem = styled(ImagemFallback)`
  height: 100px;
  width: 100px;
  border-radius: 4px;
`;

export const ProdutoInfo = styled.View`
  flex: 1;
  margin-left: 12px;
`;

export const ProdutoDescricao = styled.Text`
  font-size: ${theme.fontes.normal};
  color: ${theme.cores.texto.sobreSuperficie};
  font-weight: bold;
  text-transform: capitalize;
`;

export const ProdutoComposicao = styled.Text.attrs(() => ({
  ellipsizeMode: 'tail',
  numberOfLines: 2,
}))`
  font-size: ${theme.fontes.media};
  color: ${theme.cores.cinzas.c3};
  margin-top: 4px;
`;

export const PrecoAPatirContainer = styled.View`
  flex-direction: row;
  align-items: baseline;
`;

export const PrecoAPatir = styled.Text`
  font-size: ${theme.fontes.media};
  line-height: ${theme.fontes.media};
  color: ${theme.cores.cinzas.c2};
  margin-right: 4px;
`;

export const ProdutoPreco = styled.Text`
  font-size: ${theme.fontes.normal};
  color: ${theme.cores.sucesso};
  margin-top: 4px;
`;
