import React, { memo, useCallback } from 'react';

import semImagemImg from '../../../assets/sem-imagem.png';
import {
  Secao,
  SecaoTituloContainer,
  SecaoTitulo,
  Itens,
  VerTodosContainer,
  VerTodos,
  VerTodosTexto,
  SetaIcone,
  Item,
  ItemImagem,
  ItemNome,
  ItemPrecoContainer,
  ItemPrecoAPartir,
  ItemPreco,
} from '../styles';

const CategoriaProdutos = ({ grupo, buscarCompleto, verProduto }) => {
  const keyExtractor = useCallback(
    item => `${item.id.toString()}-produto-Cardapio`,
    [],
  );

  const renderItem = useCallback(
    ({ item }) => (
      <Item onPress={() => verProduto(item)}>
        <ItemImagem src={item.imagem} fallbackImg={semImagemImg} />

        <ItemNome>{item.descricao}</ItemNome>
        {['PIZZA', 'TAMANHO', 'PORCAO'].includes(item.tipoProduto) ? (
          <ItemPrecoContainer>
            <ItemPrecoAPartir>A partir de </ItemPrecoAPartir>
            <ItemPreco>{item.menorPrecoFormatado}</ItemPreco>
          </ItemPrecoContainer>
        ) : (
          <ItemPreco>{item.precoVendaFormatado}</ItemPreco>
        )}
      </Item>
    ),
    [verProduto],
  );

  return (
    <Secao>
      <SecaoTituloContainer>
        <SecaoTitulo>{grupo.descricao}</SecaoTitulo>
      </SecaoTituloContainer>
      <Itens
        data={grupo.produtos}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
        ListFooterComponent={() =>
          grupo.quantidadeProdutos > 5 && (
            <VerTodosContainer>
              <VerTodos onPress={() => buscarCompleto(grupo)}>
                <SetaIcone />
              </VerTodos>
              <VerTodosTexto>Ver todos</VerTodosTexto>
            </VerTodosContainer>
          )
        }
      />
    </Secao>
  );
};

export default memo(CategoriaProdutos);
