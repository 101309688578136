import { Platform } from 'react-native';

import styled, { css } from 'styled-components/native';

import Botao from '../../components/Botao';
import theme from '../../theme/index';

export const LogoUniTechContainer = styled.View`
  align-items: center;
  justify-content: center;
`;

export const DesenvolvidoPor = styled.Text`
  font-size: ${theme.fontes.grande};
  color: ${theme.cores.texto.sobreFundo};
  font-weight: bold;
  text-align: center;
  margin: 16px 0 8px 0;
`;

export const LogoUniTech = styled.Image`
  height: 150px;
  width: 150px;
  margin: 16px 0;
`;

export const RedeSocialContainer = styled.View`
  flex-direction: row;
  align-items: center;
  margin: 16px 16px 0 16px;
`;

export const RedeSocialLogo = styled.Image`
  height: 30px;
  width: 30px;
  margin-top: 8px;
`;

export const RedeSocialDescricao = styled.Text`
  margin-left: 8px;
  font-size: ${theme.fontes.normal};
  color: ${theme.cores.texto.sobreFundo};
  font-weight: bold;
  flex: 1;
`;

export const BotaoContato = styled(Botao)`
  ${Platform.select({
    web: css`
      margin: auto 16px 16px 16px;
      background-color: #003466;
      margin-bottom: 40%;
    `,
    android: css`
      margin: auto 16px 16px 16px;
      background-color: #003466;
    `,
    ios: css`
      margin: auto 16px 16px 16px;
      background-color: #003466;
    `,
  })}
`;
