import React, { memo, useCallback } from 'react';

import semImagemImg from '../../../assets/sem-imagem.png';
import {
  Secao,
  SecaoTituloContainer,
  SecaoTitulo,
  Itens,
  Item,
  ItemImagem,
  ItemNome,
} from '../styles';

const Categorias = ({ grupos, buscarCompleto }) => {
  const keyExtractor = useCallback(
    item => `${item.id.toString()}-grupos-Cardapio`,
    [],
  );

  const renderItem = useCallback(
    ({ item }) => (
      <Item onPress={() => buscarCompleto(item)}>
        <ItemImagem src={item.imagemURL} fallbackImg={semImagemImg} />
        <ItemNome>{item.descricao}</ItemNome>
      </Item>
    ),
    [buscarCompleto],
  );

  return (
    <Secao>
      <SecaoTituloContainer>
        <SecaoTitulo>Categorias</SecaoTitulo>
      </SecaoTituloContainer>
      <Itens
        data={grupos}
        keyExtractor={keyExtractor}
        maxToRenderPerBatch={4}
        windowSize={8}
        renderItem={renderItem}
      />
    </Secao>
  );
};

export default memo(Categorias);
