import React from 'react';

import { useSessao } from '../hooks/sessao';
import Splash from '../screens/Splash';
import AppRoutes from './app.routes';
import AutenticacaoRoutes from './autenticacao.routes';

const Routes = () => {
  const { sessao, carregando } = useSessao();

  if (carregando) {
    return <Splash />;
  }

  return sessao ? <AppRoutes /> : <AutenticacaoRoutes />;
};

export default Routes;
