import api from './api';

export async function listarSabores({
  pagina = 1,
  limite = 10,
  excluirSabores = '',
  produtoNome,
}) {
  const res = await api.get(`/sabores`, {
    params: {
      pagina,
      limite,
      produtoNome,
      excluirSabores,
    },
  });
  return res.data;
}
