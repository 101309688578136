import React from 'react';

import {
  Container,
  ModalContainer,
  ModalBackdrop,
  Conteudo,
  Titulo,
  BotaoFechar,
  Text,
} from './styles';

const ModalPwa = ({ titulo, children, onClose, visivel, ...rest }) => {
  return (
    <Container>
      <ModalContainer visible={visivel} {...rest}>
        <ModalBackdrop>
          <Conteudo>
            <Titulo>{titulo}</Titulo>
            {children}
            <BotaoFechar onPress={onClose} desabilitado={false}>
              <Text>Ok</Text>
            </BotaoFechar>
          </Conteudo>
        </ModalBackdrop>
      </ModalContainer>
    </Container>
  );
};

export default ModalPwa;
