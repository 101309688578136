import { Platform } from 'react-native';

import styled, { css } from 'styled-components/native';
import { MaterialIcons } from '@expo/vector-icons';

import theme from '../../theme/index';

export const ContainerInput = styled.View`
  padding: 16px;
`;

export const GruposTitulo = styled.Text`
  font-size: ${theme.fontes.normal};
  color: ${theme.cores.texto.sobreFundo};
  margin: 16px 16px 8px 16px;
`;

export const Icone = styled(MaterialIcons).attrs(() => ({
  color: theme.cores.cinzas.c2,
  size: 30,
}))`
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  margin-right: 138px;
  margin-top: 8px;
`;

export const Grupos = styled.FlatList.attrs(() => ({
  showsVerticalScrollIndicator: false,
}))`
  ${Platform.select({
    web: css`
      flex: 100vh;
      margin: 0 8px;
    `,
    android: css`
      flex: 1;
      margin: 0 8px;
    `,
    ios: css`
      flex: 1;
      margin: 0 8px;
    `,
  })}
`;

export const Grupo = styled.TouchableOpacity`
  ${Platform.select({
    web: css`
      border-radius: 4px;
      flex: 100vh;
      height: 120px;
      margin: 0 8px 16px 8px;
    `,
    android: css`
      border-radius: 4px;
      flex: 1;
      height: 120px;
      margin: 0 8px 16px 8px;
    `,
    ios: css`
      border-radius: 4px;
      flex: 1;
      height: 120px;
      margin: 0 8px 16px 8px;
    `,
  })}
`;

export const GrupoInvisivel = styled.TouchableOpacity`
  ${Platform.select({
    web: css`
      opacity: 0;
      margin: 0 8px 16px 8px;
    `,
    android: css`
      flex: 1;
      opacity: 0;
      margin: 0 8px 16px 8px;
    `,
    ios: css`
      flex: 1;
      opacity: 0;
      margin: 0 8px 16px 8px;
    `,
  })}
`;

export const GrupoImagem = styled.ImageBackground`
  height: 100%;
  border-radius: 4px;
`;

export const GrupoFundo = styled.View`
  ${Platform.select({
    web: css`
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.3);
      height: 100%;
      align-items: center;
      justify-content: center;
      padding: 8px;
    `,
    android: css`
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.3);
      height: 100%;
      align-items: center;
      justify-content: center;
      padding: 8px;
    `,
    ios: css`
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.3);
      height: 100%;
      align-items: center;
      justify-content: center;
      padding: 8px;
    `,
  })}
`;

export const GrupoNome = styled.Text`
  font-size: ${theme.fontes.normal};
  color: #fff;
  text-align: center;
  text-transform: capitalize;
`;
