import { Platform } from 'react-native';
import Animated from 'react-native-reanimated';

import Constants from 'expo-constants';
import styled, { css } from 'styled-components/native';

import theme from '../../theme/index';

export const ContainerNormal = styled.SafeAreaView`
  ${Platform.select({
    web: css`
      height: 100vh;
      background-color: ${theme.cores.fundo};
    `,
    android: css`
      flex: 1;
      background-color: ${theme.cores.fundo};
    `,
    ios: css`
      flex: 1;
      background-color: ${theme.cores.fundo};
    `,
  })}
`;

export const ContainerRolavel = styled(Animated.ScrollView).attrs(() => ({
  showsVerticalScrollIndicator: false,
}))`
  ${Platform.select({
    web: css`
      background-color: ${theme.cores.fundo};
    `,
    android: css`
      flex: 1;
      background-color: ${theme.cores.fundo};
    `,
    ios: css`
      flex: 1;
      background-color: ${theme.cores.fundo};
    `,
  })}
`;

export const StatusBarFundo = styled.View`
  ${Platform.select({
    web: css`
      height: ${Constants.statusBarHeight}px;
      background-color: ${({ corStatusBar }) =>
        corStatusBar || theme.cores.fundo};
    `,
    android: css`
      height: ${Constants.statusBarHeight}px;
      background-color: ${({ corStatusBar }) =>
        corStatusBar || theme.cores.fundo};
    `,
    ios: css`
      height: ${Constants.statusBarHeight}px;
      background-color: ${({ corStatusBar }) =>
        corStatusBar || theme.cores.fundo};
    `,
  })}
`;
