import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import erroImg from '../../../assets/embarrassed.png';
import Carregando from '../../../components/Carregando';
import Erro from '../../../components/Erro';
import Input from '../../../components/Input';
import Modal from '../../../components/Modal';
import Produtos from '../../../components/Produtos';
import STATUS from '../../../config/status';
import { useSessao } from '../../../hooks/sessao';
import { useTema } from '../../../hooks/tema';
import { listarSabores } from '../../../services/sabores';

// import { Container } from './styles';

const EscolhaSabores = ({
  visivel,
  onClose,
  idSaborPadrao,
  sabores,
  selecionarSabor,
}) => {
  const [status, setStatus] = useState(STATUS.CARREGANDO);
  const [saboresDados, setSaboresDados] = useState({
    produtos: [],
    total: 0,
    quantidade: 0,
    paginas: 0,
  });
  const [pagina, setPagina] = useState(1);
  const [produtoNome, setProdutoNome] = useState('');

  const { control, handleSubmit } = useForm();
  const { empresa } = useSessao();
  const { tema } = useTema();

  const excluirSabores = useMemo(() => {
    return [idSaborPadrao, ...sabores.map(sabor => sabor.id)].join(',');
  }, [idSaborPadrao, sabores]);

  const buscarSabores = useCallback(
    async ({ produtoNomeBuscar, pg }) => {
      try {
        setStatus(STATUS.CARREGANDO);
        const dados = await listarSabores({
          idEmpresa: empresa.id,
          produtoNome: produtoNomeBuscar,
          pagina: pg,
          excluirSabores,
        });
        setSaboresDados(dados);
        setStatus(STATUS.SUCESSO);
      } catch (error) {
        setStatus(STATUS.ERRO);
      }
    },
    [empresa.id, excluirSabores],
  );

  const paginacao = useCallback(async () => {
    if (pagina === saboresDados.paginas) {
      return;
    }

    try {
      const dados = await listarSabores({
        idEmpresa: empresa.id,
        pagina: pagina + 1,
        produtoNome,
        excluirSabores,
      });

      setPagina(pagina + 1);

      setSaboresDados(state => ({
        ...dados,
        produtos: [...state.produtos, ...dados.produtos],
      }));
    } catch (error) {
      setStatus(STATUS.ERRO);
    }
  }, [empresa.id, excluirSabores, pagina, produtoNome, saboresDados.paginas]);

  const onSubmit = useCallback(
    async ({ produtoNomeBuscar }) => {
      setPagina(1);
      setProdutoNome(produtoNomeBuscar);
      await buscarSabores({ produtoNomeBuscar });
    },
    [buscarSabores],
  );

  const escolherSabor = useCallback(
    sabor => {
      selecionarSabor(sabor);
      onClose();
    },
    [onClose, selecionarSabor],
  );

  useEffect(() => {
    (async () => {
      if (visivel) {
        setPagina(1);
        setProdutoNome('');
        await buscarSabores({});
      }
    })();
  }, [buscarSabores, visivel]);

  return (
    <Modal
      visivel={visivel}
      titulo="Selecione o sabor"
      onClose={onClose}
      swipeDirection={null}
    >
      <Controller
        control={control}
        name="produtoNomeBuscar"
        defaultValue=""
        render={({ onChange, onBlur, value }) => (
          <Input
            icone="search"
            placeholder="Buscar sabor"
            returnKeyType="search"
            onBlur={onBlur}
            editable={status !== STATUS.CARREGANDO}
            onChangeText={texto => onChange(texto)}
            value={value}
            style={{
              marginBottom: 16,
              backgroundColor: tema.cores.cinzas.c1,
            }}
            onSubmitEditing={handleSubmit(onSubmit)}
          />
        )}
      />

      {status === STATUS.CARREGANDO && <Carregando />}

      {status === STATUS.ERRO && (
        <Erro imagem={erroImg} mensagem="Desculpe! Ocorreu um erro" />
      )}
      {status === STATUS.SUCESSO && (
        <Produtos
          produtos={saboresDados.produtos}
          paginacao={paginacao}
          total={saboresDados.total}
          chave="Sabores"
          onPressProduto={escolherSabor}
        />
      )}
    </Modal>
  );
};

export default memo(EscolhaSabores);
