import { Platform } from 'react-native';

import styled from 'styled-components/native';

import Botao from '../../components/Botao';

export const Container = styled.View`
  flex: 1;
  background-color: #003366;
  justify-content: center;
`;

export const BotaoIr = styled(Botao).attrs(() => ({
  textoStyle: {
    color: '#000',
  },
}))`
  background-color: ${props =>
    props.desabilitado ? props.theme.cores.cinzas.c3 : '#fff'};
  margin-top: 30px;

  margin-right: 70px;
  margin-left: 70px;
  margin-bottom: 50px;
`;

export const TextoInfoContainer = styled.Text`
  margin-top: 0;
  color: #fff;
  font-weight: normal;
`;

export const BotaoCod = styled.TouchableOpacity`
  padding: 24px;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);

  bottom: 0;
  left: 0;
  width: 100%;
`;

export const ContainerAviso = styled.View`
  margin-bottom: auto;
  margin-top: auto;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  width: 80%;
  height: 220px;
  border-radius: 10px;

  margin-right: auto;
  margin-left: auto;
`;

export const TextAviso = styled.Text`
  align-items: center;
  justify-content: center;
  font-size: 20px;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  font-family: Montserrat_500Medium;
`;

export const ViewImage = styled.View`
  align-items: center;
  justify-content: center;
`;

export const ContainerInit = styled.View`
  align-items: center;
  justify-content: center;
`;

export const ContainerUso = styled.View`
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #000;
`;

export const TextInput = styled.Text`
  /* margin-top: ${Platform.OS === 'ios' ? `${5}%` : `${0}%`}; */
  text-align: center;
  font-size: 30px;
  color: #fff;
`;

export const TextInfo = styled.Text`
  margin-right: 10px;
  margin-left: 10px;
  text-align: center;
  font-size: 20px;
  color: #fff;
  opacity: 1;
`;
