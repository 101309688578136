import { RFValue } from 'react-native-responsive-fontsize';
import styled, { css } from 'styled-components/native';
import { TextInput } from 'react-native';
import { COLORS } from '../../constants';

export const Container = styled.View`
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const IconContainer = styled.View`
  height: 56px;
  width: 16%;
  justify-content: center;
  align-items: center;
  margin-right: 2px;
  background-color: ${COLORS.white};
  ${({ isFocused }) =>
    isFocused &&
    css`
      border-bottom-width: 2px;
      border-top-width: 2px;
      border-right-width: 2px;
      border-bottom-color: ${COLORS.black};
    `};
`;

export const InputText = styled(TextInput)`
  height: 56px;
  width: 84%
  background-color: ${COLORS.white};
  color: ${COLORS.black};
  border-width: 0px;
  font-size: ${RFValue(20)}px;
  padding: 0 23px;

  ${({ isFocused }) =>
    isFocused &&
    css`
      color: ${COLORS.black};
      border-bottom-width: 2px;
      border-top-width: 2px;
      border-left-width: 2px;
      border-bottom-color: ${COLORS.black};
    `}
`;
