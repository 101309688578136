import { Platform } from 'react-native';
import Animated from 'react-native-reanimated';

import { MaterialIcons } from '@expo/vector-icons';
import Constants from 'expo-constants';
import styled, { css } from 'styled-components/native';

import theme from '../../../theme/index';

export const Nav = styled(Animated.View)`
  height: ${({ altura }) => altura}px;
  padding: ${Constants.statusBarHeight}px 16px 0 8px;
  background-color: ${theme.cores.superficie};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  flex-direction: row;
  align-items: center;
`;

export const NavProdutoNome = styled.Text.attrs(() => ({
  ellipsizeMode: 'tail',
  numberOfLines: 1,
}))`
  ${Platform.select({
    web: css`
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.fontes.normal};
      line-height: ${theme.fontes.normal};
      color: ${theme.cores.texto.sobreSuperficie};
      text-align: center;
      margin: 0 60px;
    `,
    android: css`
      flex: 1;
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.fontes.normal};
      line-height: ${theme.fontes.normal};
      color: ${theme.cores.texto.sobreSuperficie};
      text-align: center;
      margin: 0 60px;
    `,
    ios: css`
      flex: 1;
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.fontes.normal};
      line-height: ${theme.fontes.normal};
      color: ${theme.cores.texto.sobreSuperficie};
      text-align: center;
      margin: 0 60px;
    `,
  })}
`;

export const NavBotaoVoltar = styled.TouchableOpacity`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: rgba(54, 54, 54, 1);
  align-items: center;
  justify-content: center;

  position: absolute;
  left: 16px;
  top: ${({ alturaNav }) => alturaNav / 2 - 10}px;
  z-index: 2000;
`;

export const NavBotaoVoltarIcone = styled(MaterialIcons).attrs(() => ({
  name: 'arrow-back',
  size: 25,
  color: theme.cores.primaria,
}))``;

export const ContainerInterno = styled(Animated.ScrollView).attrs(() => ({
  showsVerticalScrollIndicator: false,
}))`
  ${Platform.select({
    web: css`
    `,
    android: css`
      flex: 1;
      background-color: ${theme.cores.superficie};
    `,
    ios: css`
      flex: 1;
      background-color: ${theme.cores.superficie};
    `,
  })}
`;
