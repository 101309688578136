import React from 'react';
import { Image, StyleSheet, ScrollView, StatusBar } from 'react-native';

import {
  TextInput,
  ViewImage,
  ContainerInit,
  Container,
  ContainerUso,
  TextInfo,
} from './styles';

const NotFound = () => {
  const styles = StyleSheet.create({
    image: {
      width: 200,
      height: 200,
    },
  });
  return (
    <>
      <StatusBar backgroundColor="#003366" barStyle="light-content" />
      <ScrollView
        keyboardShouldPersistTaps="handled"
        scrollable
        contentContainerStyle={{ flexGrow: 1 }}
      >
        <Container>
          <ContainerInit>
            <TextInput>MenuCheff</TextInput>
          </ContainerInit>
          <ViewImage>
            <Image
              source={require('../../../assets/icon.png')}
              style={styles.image}
              resizeMode="cover"
            />
          </ViewImage>
          <ContainerUso>
            <TextInfo>Verifique a url digitada</TextInfo>
          </ContainerUso>
        </Container>
      </ScrollView>
    </>
  );
};

export default NotFound;
